// BASE.SCSS

/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
	// padding-top: 3rem;
	// padding-bottom: 3rem;
	// color: #000;
}


/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
.carousel {
	margin-bottom: 4rem;
	background-color: #000;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
	z-index: 10;
	top: 10rem;
}

/* Declare heights because of positioning of img element */
.carousel-item {
	img {
		display: block;
		margin: 0 auto;
		max-width: 100%;
	}
	.slideimage {
		img {
			max-width: 100%;
		}
	}
	.slidecaption {
		max-width: 450px;
	}
}


/* TABLE BASE SETTINGS
------------------------------ */
table {
	th {
		background-color: #e3e3e3;
		font-weight: bold;
	}
	td {
		ul {
			list-style: none;
			margin-bottom: 0;
			padding-left: 0;
		}
	}
&.table-bordered {
	tr:first-child {
		th,
		td {
			border-top: 1px solid #999;
		}
	}
	th {
		border-bottom: 1px solid #999;
		border-right: 1px solid #999;
	}
	td {
		border-bottom: 1px solid #999;
	}
}
}

.case-related {
	padding-bottom: 1rem;
	// margin-top: 4.25rem;
	.unit-title {
		h4 {
			font-size: 1.375rem;
			color: #333;
			span {
				display: block;
				padding: 1.5rem 0 0;
			}
		}
	}
	.unit-sub {
		margin-bottom: 2rem;
		.container {
			max-width: 1200px;
			padding-left: 0;
			padding-right: 0;
		}
		.case-title {
			background-color: #333;
			h5 {
				color: #fff;
				font-size: 1.25rem;
				font-weight: bold;
				margin-bottom: 0;
				padding: 1rem 1.5rem;
			}
		}
		.case-content {
			background-color: #ececec;
			padding: 3rem 3.2rem 0 3.2rem;;
			ul {
				list-style: none;
				padding-left: 0;
				margin-bottom: .5rem;
				li {
					// margin-right: 1rem;
				}
			}
			.fwb {
				font-weight: bold;
			}
			a {
				text-decoration: underline;
			}
		}
		.case-image {
			img {
				max-width: 100%;
			}
		}
	}
}

.look-otherwise {
	margin-bottom: 2.25rem;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		.linkbanner {
			background-color: #ececec;
			width: 100%;
			height: 120px;
			a {
				display: block;
				width: 100%;
				height: 120px;
				background: url(../images/linktitle_othercase.png) 50% 50% no-repeat;
				img {
					display: none;
				}
			}
		}
	}
}

.mass-pro {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
}



/* HOME PAGE SETTINGS
------------------------------ */
.top-strongpoint {
	margin-bottom: 5rem;
	.mainlead {
		width: 1060px;
		margin: 0 auto;
	}
	.point {
		img {
			width: 100%;
			padding: 1px;
		}
	}
}

.top-solution {
	margin-bottom: 4.5rem;
	.solutions {
		.container {
			max-width: 1768px;
		}
		.row {
			border-bottom: 1px solid #d7d6cf;
			padding-top: 6px;
			padding-bottom: 6px;
		&.solr1 {
			border-top: 1px solid #d7d6cf;
		}
		}
		.solution {
			img {
				max-width: 100%;
				border-left: 1px solid #d7d6cf;
			}
		}
	}
}

.top-casestudy {
	margin-bottom: 5rem;
	.container {
		max-width: 1400px;
		.case-cards {
			.card {
				border: none;
				.card-body {
					padding: 0;
				}
				.card-title {
					font-size: 1rem;
					margin-top: 1rem;
					line-height: 1.4;
					a {
						color: #333;
						text-decoration: underline;
					}
				}
				.card-img-top {
					border-radius: 0;
					width: 100%;
					max-height: 322px;
					object-fit: cover;
					object-position: 50% 50%;
				}
			}
		}
	}
}

.top-topics {
	padding-top: 3rem;
	border-top: 1px solid #e7e7e7;
	margin-bottom: 3rem;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	.topics-title {
		margin: 0;
		padding: 0;
		h2 {
			img {
				max-width: 100%;
			}
		}
	}

	dl {
		margin-left: 1rem;
		dt {
			border-bottom: 1px solid #e7e7e7;
			mergin: 0;
			padding: 1rem 1rem 1rem 1rem;
			p {
				margin-bottom: 0;
			}
		&:last-of-type {
			border-bottom: none;
		}
		}
		dd {
			border-bottom: 1px solid #e7e7e7;
			margin: 0;
			padding: 1rem;
			background: url(../images/rightangle_g.png) 98% 50% no-repeat;
			p {
				margin-bottom: 0;
			}
			a {
				color: #333;
			}
		&:last-of-type {
			border-bottom: none;
		}
		}
	}
	.topic-date {
		padding-left: 1rem;
		font-size: .75rem;
		span {
			margin-left: 1rem;
		}
	}
}

.home {
	.banner2 {
		margin-bottom: 5rem;
	}
}


/* RESPONSIVE CSS
-------------------------------------------------- */
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1800px) {
	.carousel-item {
		.slidecaption {
			padding-top: 14.5rem;
			padding-left: 4rem;
		}
	}
}

@media (max-width: 1800px) {
	.carousel-item {
		.slidecaption {
			padding-top: 13rem;
			padding-left: 2rem;
			img {
				// max-width: 90%;
			}
		}
	}
}

@media (max-width: 1600px) {
	.carousel-item {
		.slidecaption {
			padding-top: 11rem;
			padding-left: 0;
			img {
				max-width: 90%;
			}
		}
	}
}


@media (min-width: 1200px) {
	.carousel-item {
		.slidecaption {
			padding-top: 8rem;
		}
	}
}



// Medium devices (tablets, less than 1200px)
@media (max-width: 1199px) {
	.carousel-item {
		.slidecaption {
			padding-top: 6rem;
		}
	}
	.mass-pro {
		.container {
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
			img {
				width: 100%;
			}
		}
	}
	.top-casestudy {
		.container {
			.case-cards {
				.card {
					.card-img-top {
						max-height: 274px;
					}
				}
			}
		}
	}

}



// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
	.solutions {
		.solution {
		&.sol4,
		&.sol8 {
			img {
				border-right: 1px solid #d7d6cf;
			}
		}
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
	.container {
		max-width: 100%;
	}
	.carousel {
		margin-bottom: 3rem;
	}
	.carousel-item {
		.slidecaption {
			padding-top: 2rem;
			padding-bottom: 4rem;
			margin: 0 auto;
			img {
				max-width: 100%;
				display: block;
			}
		}
	}
	.top-strongpoint,
	.top-solution {
		.unit-title {
			h2 {
				img {
					max-width: 90%;
				}
			}
		}
	}
	.solutions {
		.solution {
			width: 100%;
			img {
				width: 100%;
			}
		&.sol4,
		&.sol8 {
			img {
				border-right: 1px solid #d7d6cf;
			}
		}
		&.sol2,
		&.sol6 {
			img {
				border-right: 1px solid #d7d6cf;
			}
		}
		&.sol1,
		&.sol2,
		&.sol5,
		&.sol6 {
			img {
				border-bottom: 1px solid #d7d6cf;
			}
		}
		}
	}

	.top-strongpoint {
		.point {
			img {
				width: 100%;
				padding: 1px;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}

	.top-casestudy {
		.container {
			.case-cards {
				.card {
					.card-img-top {
						height: 221px;
					}
				}
			}
		}
	}


	.top-topics {
		padding-top: 0;
		border-top: none;
		.topics-title {
			background-color: #000;
			h2 {
				margin-top: 1rem;
				margin-bottom: 1rem;
				display: block;
				width: 100%;
				height: 22px;
				background: url(../images/top_topics_letter.png) 50% 0 no-repeat;
				text-indent: -9999px;
				img {
					display: none;
				}
			}
		}
		dl {
			margin-left: 0;
			max-width: 100%;
		}
	}
}

@media (min-width: 992px) and (max-width: 1199px) {

}


@media (min-width: 576px) and (max-width: 767px) {

}

// Extra small devices (portrait phones, less than 700px)
@media (max-width: 767px) {
	.carousel {
		margin-bottom: 2rem;
	}
	.carousel-item {
		.slidecaption {
			padding-top: 1.5rem;
			padding-bottom: 3rem;
			img {
				max-width: 80%;
				height: auto;
			}
		}
	}
	.top-strongpoint {
		margin-bottom: 2rem;
		.unit-title {
			h2 {
				img {
					max-width: 98%;
				}
			}
			p {
				font-size: .9rem;
			}
		}
	}
	.top-solution {
		.unit-title {
			h2 {
				img {
					max-width: 98%;
				}
			}
			p {
				font-size: .9rem;
			}
		}
	}
	.solutions {
		.solution {
		&.sol1,
		&.sol3,
		&.sol5,
		&.sol7 {
			img {
				border-right: 1px solid #d7d6cf;
			}
		}
		&.sol3,
		&.sol5,
		&.sol7 {
			img {
				border-bottom: 1px solid #d7d6cf;
			}
		}
		}
	}
	.top-casestudy {
		margin-bottom: 3rem;
		.container {
			.case-cards {
				text-align: center;
				.card {
					.card-img-top {
						height: auto;
						max-width: 460px;
					}
				}
			}
		}
	}
	.top-topics {
		dl {
			dt {
				border-bottom: none;
			}
			dd {
				font-size: .875rem;
				padding-top: 0;
			&:last-of-type {
				border-bottom: 1px solid #d7d6cf;
			}
			p {
				padding-right: 2rem;
				padding-left: 1rem;
			}
			}
		}
	}
}




